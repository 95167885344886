import React from 'react';
import { FormattedMessage } from 'react-intl';

import AccordionList from 'components/accordion-list';

import './styles.scss';

const Faqs = [
  {
    title: 'works.faqs.f1.title',
    description: 'works.faqs.f1.description'
  },
  {
    title: 'works.faqs.f2.title',
    description: 'works.faqs.f2.description'
  },
  {
    title: 'works.faqs.f3.title',
    description: 'works.faqs.f3.description'
  },
  {
    title: 'works.faqs.f4.title',
    description: 'works.faqs.f4.description'
  }
];

const HowItWorksFaq = () => {
  const faqs = Faqs;

  return (
    <div className="how-it-works-faq">
      <div className="container">
        <h6 className="how-it-works-faq__title">
          <FormattedMessage id="works.faqs.title" />
        </h6>
        <h1 className="h1--display how-it-works-faq__description">
          <FormattedMessage id="works.faqs.description" />
        </h1>
        <div className="row justify-content-center">
          <div className="col col-12 col-lg-8 col-xl-6">
            <AccordionList
              list={faqs}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default HowItWorksFaq;
