import React from 'react';

import Link from 'utils/localized-link';

import Banner from 'components/sections/banner';
import Benefits from 'components/sections/benefits';
import HowItWorksBuy from 'components/sections/how-it-works-buy';
import HowItWorksFaq from 'components/sections/how-it-works-faq';
import HowItWorksHeader from 'components/sections/how-it-works-header';
import HowItWorksOrder from 'components/sections/how-it-works-order';
import Layout from 'components/layout';
import SEO from 'components/seo';
import Separator from 'components/separator';

import ClockIcon from 'images/icons/clock.inline.svg';
import DollarSignIcon from 'images/icons/dollar-sign.inline.svg';
import MultiCheckboxIcon from 'images/icons/multi-checkbox.inline.svg';


const WEB_APP_URL = process.env.GATSBY_WEB_APP_URL;

const BENEFITS = [
  {
    title: 'works.benefits.b1.title',
    description: 'works.benefits.b1.description',
    icon: ClockIcon
  },
  {
    title: 'works.benefits.b2.title',
    description: 'works.benefits.b2.description',
    icon: DollarSignIcon
  },
  {
    title: 'works.benefits.b3.title',
    description: 'works.benefits.b3.description',
    icon: MultiCheckboxIcon
  }
];

const bannerButtons = [
  {
    external: true,
    linkProps: {
      to: `${WEB_APP_URL}/register`
    },
    noNewTab: true,
    light: true,
    noArrow: true,
    small: true,
    text: 'works.banner.buttons.sign_up'
  },
  {
    linkComponent: Link,
    linkProps: {
      to: '/contacts'
    },
    light: true,
    noArrow: true,
    noBackground: true,
    small: true,
    text: 'works.banner.buttons.contacts'
  }
];

const bannerImage = 'create-account.png';

const HowItWorksPage = ({ pageContext: { locale } }) => (
  <Layout locale={locale}>
    <SEO title="works.title" />
    <HowItWorksHeader />
    <Benefits
      benefits={BENEFITS}
      title="works.benefits.title"
    />
    <HowItWorksOrder />
    <HowItWorksBuy />
    <Separator />
    <HowItWorksFaq />
    <Banner
      buttons={bannerButtons}
      image={bannerImage}
      text="works.banner.text"
      title="works.banner.title"
    />
  </Layout>
);

export default HowItWorksPage;
